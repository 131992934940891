import { z } from "zod";
import { Request } from "express";
import { chain, invert, map } from "lodash";
import { NetworkConfig, WebhookConfig } from "./appConfig";
import { RecaptchaClientType } from "./recaptcha";
import { Flavor } from "./commonTypes";
import { WalletCredentials } from "./walletCredentials";

// Using Flavor instead of Brand to make it easier to work with legacy code
export type Eth = Flavor<number, "Eth">;
export type Wei = Flavor<bigint, "Wei">;

/******* Add New Supported Chains and Networks into BaseBlockChain and FaucetRequestNetwork ********/
export enum FaucetRequestNetwork {
  MAINNET = "mainnet",
  GOERLI = "goerli",
  MUMBAI = "mumbai",
  SEPOLIA = "sepolia",
  AMOY = "amoy",
  HOLESKY = "holesky",
  TESTNET = "testnet",
  MINATO = "minato",
}

export enum BaseBlockChain {
  ETH = "eth",
  MATIC = "matic",
  BASE = "base",
  OPTIMISM = "optimism",
  ARBITRUM = "arbitrum",
  STARKNET = "starknet",
  ZKSYNC = "zksync",
  WORLD_CHAIN = "world_chain",
  SEI = "sei",
  SONEIUM = "soneium",
  CROSSFI = "crossfi",
}

export const HUMANIZED_BLOCK_CHAIN_NAME: Record<BaseBlockChain, string> = {
  [BaseBlockChain.ETH]: "ethereum",
  [BaseBlockChain.MATIC]: "polygon",
  [BaseBlockChain.BASE]: "base",
  [BaseBlockChain.OPTIMISM]: "optimism",
  [BaseBlockChain.ARBITRUM]: "arbitrum",
  [BaseBlockChain.STARKNET]: "starknet",
  [BaseBlockChain.ZKSYNC]: "zksync",
  [BaseBlockChain.WORLD_CHAIN]: "world-chain",
  [BaseBlockChain.SEI]: "sei",
  [BaseBlockChain.SONEIUM]: "soneium",
  [BaseBlockChain.CROSSFI]: "crossfi",
};

/******* END ********/

export type FaucetChainNetworkKey =
  | `${BaseBlockChain}_${FaucetRequestNetwork}`
  | "ALL";

/**
 * alchemy.com/faucets/ethereum-sepolia uses a different naming convention that is better for humans
 * {
 *   "eth_goerli": "ethereum-goerli",
 *   "eth_sepolia": "ethereum-sepolia",
 * }
 */
export const ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK = (() => {
  return chain(Object.values(BaseBlockChain))
    .flatMap((blockChain) => {
      return map(Object.values(FaucetRequestNetwork), (network) => {
        return [blockChain, network] as [BaseBlockChain, FaucetRequestNetwork];
      });
    })
    .reduce(
      (acc, [blockChain, network]) => {
        acc[`${blockChain}_${network}`] =
          `${HUMANIZED_BLOCK_CHAIN_NAME[blockChain]}-${network}`;
        return acc;
      },
      {} as Record<FaucetChainNetworkKey, string>,
    )
    .value();
})();

export const CHAIN_NETWORK_BY_ALCHEMY_COM_FAUCET_NAMESPACE = invert(
  ALCHEMY_COM_FAUCET_NAMESPACE_BY_CHAIN_NETWORK,
) as Record<string, FaucetChainNetworkKey>;

/* Please DO NOT Add any optional parameters into this data structure */
export const FaucetRequestNetworkInfo = z.object({
  network: z.nativeEnum(FaucetRequestNetwork),
  chain: z.nativeEnum(BaseBlockChain),
});
export type FaucetRequestNetworkInfo = z.infer<typeof FaucetRequestNetworkInfo>;

export interface FaucetAppNetworkInfo {
  id: FaucetChainNetworkKey;
  networkInfo: FaucetRequestNetworkInfo;
  fullName: string;
  networkDisplayName: string;
  transactionUrl: string;
  bridgeUrl: string;
  reserveWalletAddress: string;
  affiliateReferral: string;
  tokenName: string;
  fullTokenName: string;
  chainName: string;
  requiresAuthentication: boolean;
  minMainnetBalanceRequired: {
    amount: Wei;
    display: string;
  };
  activeTeamUpsellEnabled?: boolean;
  activeTeamDripAmount: string;
  authDripAmount: string;
  nonAuthDripAmount: string;
  gaMeasurementId: string;
  recaptchaClientKey: string;
  recaptchaClientType: RecaptchaClientType;
  bannerMessage: string;
  noAuthBannerMessage: string;
  hookConfiguration: WebhookConfig;
  rateLimitDurationSeconds: number;
}

export type BasicFaucetAppNetworkInfo = Pick<
  FaucetAppNetworkInfo,
  | "id"
  | "networkInfo"
  | "fullName"
  | "networkDisplayName"
  | "chainName"
  | "rateLimitDurationSeconds"
>;

export enum Env {
  DEV = "dev",
  STAGING = "stg",
  PROD = "prod",
}

export enum FaucetReservesLevel {
  AVAILABLE = "available",
  BUFFER_PASSED = "buffer",
  LOW_RESERVES = "low_reserves",
  EMPTY = "empty",
}

export enum FaucetInvalidRequestType {
  INVALID_WALLET_ADDRESS = "InvalidWalletAddress",
}

/* START API DATA STRUCTURES */
export enum FaucetResponseStatus {
  ERROR = "error",
  SUCCESS = "success",
}

/* FAUCET REQUEST DATA STRUCTURES */
export interface FaucetRequestParams {
  toAddress: string;
  clientRequestId: string;
  reCAPTCHAValue: string;
  alchemyUserId: string;
}

export interface EasRequestParams {
  toAddress: string;
}

export interface EasResponse {
  error?: string;
}

export type PossibleFaucetRequest = Request<
  unknown,
  unknown,
  FaucetRequestParams
>;

export interface FaucetRequestData {
  networkInfo: FaucetRequestNetworkInfo;
  toAddress: string;
  resolvedToAddress: string;
  ipAddress?: string;
  clientRequestId: string;
  requestId: string;
  reCAPTCHAValue: string;
  isAuthed: boolean;
  isActiveTeam: boolean;
  authchemyUserId?: number;
  authchemyTeamId?: number;
  mainnetBalance?: bigint;
}

export interface FaucetRequestDrip {
  amount: string;
  displayAmount: string;
}

export interface FaucetRateLimitData {
  toAddress: string;
  ipAddress: string;
}

export interface FaucetRequestErrorResponse {
  networkInfo: FaucetRequestNetworkInfo;
  toAddress: string;
  resolvedToAddress: string;
  message: string;
  code: number;
  responseStatus: FaucetResponseStatus.ERROR;
  dangerous_htmlString?: string;
}

export interface FaucetRequestSuccessResponse {
  networkInfo: FaucetRequestNetworkInfo;
  toAddress: string;
  resolvedToAddress: string;
  dripAmount: FaucetRequestDrip;
  responseStatus: FaucetResponseStatus.SUCCESS;
  requestId: string;
  transactionHash?: string;
  transactionURL?: string;
}

export type FaucetRequestResponse =
  | FaucetRequestSuccessResponse
  | FaucetRequestErrorResponse;

export interface FaucetClientServerData {
  RECAPTCHA_CLIENT_KEY: string;
  RECAPTCHA_CLIENT_TYPE: RecaptchaClientType;
  API_SERVER_URL: string;
  AUTH_URL: string;
  ONBOARDING_URL: string;
  APP_NETWORK_INFO: FaucetAppNetworkInfo;
  SUPPORTED_NETWORK_CONFIGS: BasicFaucetAppNetworkInfo[];
  WEBAPP_API_SERVER_URL: string;
}

export type BaseNetworkConfig = {
  activeTeamUpsellEnabled: boolean;
  gasPriceMultiplier: number;
  gasMultiplier: number;
  maxIPAddressAttemptsAllowed: {
    nonAuth: number;
    freeAccount: number;
    paidAccount: number;
  };
  rateLimitDurationSeconds: number;
  recaptchaClientType: RecaptchaClientType;
  requiresAuthentication: boolean;
  reserveLevels: {
    available: number;
    buffer: number;
    lowReserve: number;
    empty: number;
  };
};

export const BASE_NETWORK_CONFIG: BaseNetworkConfig = {
  activeTeamUpsellEnabled: true,
  gasPriceMultiplier: 3,
  gasMultiplier: 3,
  maxIPAddressAttemptsAllowed: {
    nonAuth: 1,
    freeAccount: 5,
    paidAccount: 50,
  },
  rateLimitDurationSeconds: 259200, // 72 hrs
  recaptchaClientType: RecaptchaClientType.NORMAL,
  requiresAuthentication: true,
  reserveLevels: {
    available: 500,
    buffer: 200,
    lowReserve: 100,
    empty: 0,
  },
};

export type FaucetNetworkConfig = BaseNetworkConfig &
  Pick<
    NetworkConfig,
    | "bridgeUrl"
    | "chain"
    | "chainDisplayName"
    | "currency"
    | "explorerUrl"
    | "gaMeasurementId"
    | "networkDisplayName"
    | "shouldENSOnMainnet"
    | "subdomains"
  > & {
    // These both need some frontend refactoring, but leaving them in as is for now
    bannerMessage: string;
    noAuthBannerMessage: string;

    drip: {
      amount: Wei;
      display: string;
    };
    minMainnetBalanceRequired: {
      amount: Wei;
      display: string;
    };
    reserveWalletAddress: string;
    rpcUrls: {
      mainnet: string;
      testnet: string;
    };
    walletCredentials: WalletCredentials[];
    webhookConfig: WebhookConfig;
  };

/* FAUCET TRANSFER STATUS REQUEST DATA STRUCTURES */

/**
 * Status meanings:
 * SUBMITTED - When a request is submitted to sqs, but yet to be processed
 * PROCESSING - When a txn hash is received from web3.sendSignedTransaction, network is indexing and mining the transaction
 * COMPLETED - When a confirmation is received for the txn hash, which means the txn is mined
 * FAILED - When processing the txn request failed
 * UNKNOWN - When the txn request cannot be found or the status is missing
 */
export enum FaucetTxnStatus {
  SUBMITTED = "submitted",
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
  UNKNOWN = "unknown",
}

export interface FaucetTransferTxnSubmitted {
  requestId: string;
  toAddress: string;
  status: FaucetTxnStatus.SUBMITTED;
}

export interface FaucetTransferTxnProcessing {
  requestId: string;
  toAddress: string;
  ipAddress: string;
  isAuthed: boolean;
  txnHash: string;
  status: FaucetTxnStatus.PROCESSING;
}

export interface FaucetTransferTxnCompleted {
  requestId: string;
  toAddress: string;
  txnHash: string;
  status: FaucetTxnStatus.COMPLETED;
}

export interface FaucetTransferTxnFailed {
  requestId: string;
  toAddress: string;
  message: string;
  status: FaucetTxnStatus.FAILED;
}

export interface FaucetTransferTxnUnknown {
  requestId: string;
  message: string;
  status: FaucetTxnStatus.UNKNOWN;
}

export type FaucetTransferTxn =
  | FaucetTransferTxnSubmitted
  | FaucetTransferTxnProcessing
  | FaucetTransferTxnCompleted
  | FaucetTransferTxnFailed
  | FaucetTransferTxnUnknown;

export interface FaucetTransferTxnsRequestResponseSuccess {
  status: FaucetResponseStatus.SUCCESS;
  data: {
    [key: string]: FaucetTransferTxn;
  };
}

export interface FaucetTransferTxnsRequestResponseFailed {
  status: FaucetResponseStatus.ERROR;
  message: string;
}

export type FaucetTransferTxnsRequestResponse =
  | FaucetTransferTxnsRequestResponseSuccess
  | FaucetTransferTxnsRequestResponseFailed;

export interface FaucetTransferTxnsRequestParams {
  networkInfo: FaucetRequestNetworkInfo;
  requestIds: string[];
}

export type PossibleFaucetTransferTxnsRequest = Request<
  unknown,
  unknown,
  FaucetTransferTxnsRequestParams
>;

export interface FaucetSendTransactionResponse {
  transactionHash: string;
  transactionResponse: {
    wait(): Promise<void>;
  };
}

export type AuthchemyUser = {
  id: number;
  extId: string;
  email: string;
  firstName: string;
  lastName: string;
  teamId: number | undefined;
  isActive: boolean;
  isStaff: boolean;
  isBillingAdmin: boolean;
  role: string;
  telegramUsername: string;
  discordUsername: string;
  hasJoinedDiscord: boolean;
  lastActive: string | undefined;
  createdAt: string;
};

export type AuthedUser = {
  id: number;
  extId: string;
  teamId?: number;
  email: string;
  isStaff: boolean;
  firstName: string;
  lastName: string;
  type: "AuthedUser";
};

export type AuthedUserError = {
  expired: boolean;
  message: string;
  type: "AuthedUserError";
};

//imply is using snake case
export interface FaucetImplyEntity {
  network: FaucetRequestNetwork;
  chain: BaseBlockChain;
  to_address: string;
  resolved_to_address: string;
  drip_amount: string;
  response_status: FaucetResponseStatus;
  transaction_hash: string;
  http_status_code: number;
  ip_address: string;
  finger_print_id: string;
  server_request_id: string;
  client_request_id: string;
  wallet_mainnet_balance: string;
  min_mainnet_balance_req: string;
  is_authed: boolean;
  is_active: boolean;
  active_team_upsell_enabled: boolean;
  user_id?: number;
  team_id?: number;
  timestamp: number;
}

export interface UserProperties {
  fcuUsage: number;
}

export type FaucetWebhookEvent = {
  type: "MINED_TRANSACTION" | "DROPPED_TRANSACTION";
  event: {
    appId: string;
    network: string;
    transaction: {
      from: string;
      hash: string;
      to: string;
    };
  };
};
